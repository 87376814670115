<template>
  <div class="about">
    <h2>Rich Text Editor</h2>
  </div>
</template>
<script>
export default {
  name: 'Richtext'
}
</script>
